import React, { useState } from "react"
import { useScrollAnim } from "src/components/hooks/hooks"

import { Button } from "components/anti/buttons/buttons"
import { Card } from "components/anti/card/card"
import { Link } from "components/anti/link/link"

import moment from "moment"
import defaultImg from "assets/img/bg_default.jpg"

export const NewsEventsList = ({ posts, categories, lang }) => {
  const [trigger, anim] = useScrollAnim()

  const dataTabs = [
    { name: lang === "en" ? "All" : "Semua", target: "all" },
    ...categories.map(data => {
      if (data.slug !== "uncategorized") {
        return { name: data.name, target: data.slug }
      }
    }),
  ]

  const [pagination, setPagination] = useState([0, 12])

  const changePage = isNext => {
    if (isNext) {
      setPagination([pagination[0] + 12, pagination[1] + 12])
    } else {
      setPagination([pagination[0] - 12, pagination[1] - 12])
    }
  }

  const newTabs = dataTabs.filter(function(element) {
    return element !== undefined
  })

  return (
    <section className="py-2 py-md-5 sc-newsEvents-list" ref={trigger}>
      <div className="container px-sm-down-3">
        <div
          id="nav-tab"
          className="nav-tabs-wrapper nav-scrollable-wrapper scrollable-fade px-2"
        >
          <div className="nav-scrollable">
            <ul className="nav nav-tabs" id="experiment" role="tablist">
              {newTabs.map((nav, i) => (
                <li className={`nav-item`} key={i}>
                  <Link
                    to={
                      nav.target === "all"
                        ? `${lang === "en" ? "" : "/id"}/news-events`
                        : `${lang === "en" ? "" : "/id"}/news-events/${
                            nav.target
                          }`
                    }
                    className={`nav-link`}
                    // activeClassName={`active`}
                  >
                    {nav.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div className="py-2 py-md-5">
        <div className="container">
          <div className="row row-4">
            {posts.slice(pagination[0], pagination[1]).map((post, i) => {
              const dataLang = lang === "en" ? post : post.translation || post
              const image = dataLang?.featuredImage?.node?.sourceUrl
                ? dataLang?.featuredImage?.node?.sourceUrl
                : defaultImg
              const label = post?.categories?.nodes[0]
              const altText = dataLang?.featuredImage?.node?.altText
              return (
                <div className="col-md-4 mb px-0 px-md-4" key={i}>
                  <Card
                    variant="boxless"
                    img={image}
                    label={label?.name}
                    title={dataLang?.title}
                    imgAlt={altText ? altText : "Image"}
                    titleClassName="text-truncate-twoline"
                    labelClassName="text-truncate-twoline mb-2 font-weight-bold"
                    imgRatio="r-4-3"
                    cardBodyClassName="px-4 px-md-0 pt-2"
                    link={`${lang === "en" ? "" : "/id"}/news-events/${
                      post?.categories?.nodes[0].slug
                    }/${post.slug}`}
                  >
                    <p className="text-muted mb-0">
                      {moment(dataLang.date).format("DD MMM YYYY")}
                    </p>
                  </Card>
                </div>
              )
            })}
          </div>
          {posts && posts.length > 12 && (
            <div className="pb-main d-flex justify-content-end">
              <div className="d-flex">
                <Button
                  onClick={() => changePage(false)}
                  className="btn btn-primary mr-3"
                  disabled={pagination[0] === 0}
                >
                  <i className="ais ai-long-arrow-left"></i>
                </Button>
                <Button
                  onClick={() => changePage(true)}
                  className="btn btn-primary"
                  disabled={pagination[1] >= posts.length}
                >
                  <i className="ais ai-long-arrow-right"></i>
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  )
}
