import React from 'react'
import { useStaticQuery, graphql } from "gatsby"

import SEO from "components/seo"
import Layout from "components/layout"
import { GlobalCover } from "components/global/cover/cover"
import { NewsEventsList } from "components/pages/news-events/news-events-list"

const NewsEvents = ({ pageContext, path }) => {
  const data = useStaticQuery(GET_NEWS_EVENTS)
  const lang = pageContext.langKey

  const cover = lang === "en" ? data.wordPress.page.newsEventsPage.cover : data.wordPress.page.translation.newsEventsPage.cover
  const bgColor = lang === "en"
    ? data?.wordPress?.page?.backgroundColor?.backgroundColor?.color
    : data?.wordPress?.page?.translation?.backgroundColor?.backgroundColor?.color
    || data?.wordPress?.page?.backgroundColor?.backgroundColor?.color
  const categories = pageContext.categories
  const posts = pageContext.posts

  const seo = lang === "en" ? data.wordPress.page.seo : data.wordPress.page.translation.seo
  const SEODefaultImg = cover?.image?.sourceUrl

  return (
    <Layout theme={bgColor || "light"} path={path} lang={lang}>
      <SEO
        title={seo.title}
        desc={seo.metaDesc}
        fbImg={seo.opengraphImage?.sourceUrl || SEODefaultImg}
        fbTitle={seo.opengraphTitle}
        fbDesc={seo.opengraphDescription}
        url={seo.href}
        twitterTitle={seo.twitterTitle}
        twitterDesc={seo.twitterDescription}

        twitterImg={seo.twitterImage?.sourceUrl || SEODefaultImg}
      />
      <GlobalCover data={cover} theme={bgColor || "light"} breadcrumb={path} />
      <NewsEventsList categories={categories} posts={posts} lang={lang} />
    </Layout>
  )
}

export default NewsEvents

const GET_NEWS_EVENTS = graphql`
query News_Events_Cover {
  wordPress {
    page(id: "news-events", idType: URI) {
      backgroundColor {
        backgroundColor {
          color
        }
      }
      newsEventsPage {
        cover {
          title
          image {
            sourceUrl
          }
        }
      }
      seo {
        title
        metaDesc
        twitterTitle
        twitterDescription
        twitterImage {
          sourceUrl
        }
        opengraphTitle
        opengraphDescription
        opengraphImage {
          sourceUrl
        }
      }
      translation(language: ID) {
        backgroundColor {
          backgroundColor {
            color
          }
        }
        newsEventsPage {
          cover {
            title
            image {
              sourceUrl
            }
          }
        }
        seo {
          title
          metaDesc
          twitterTitle
          twitterDescription
          twitterImage {
            sourceUrl
          }
          opengraphTitle
          opengraphDescription
          opengraphImage {
            sourceUrl
          }
        }
      }
    }
  }
}

`